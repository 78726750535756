// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { CLogClient, PanoramaClient } from './log-clients';
import { buildMetricDetail, buildMetricName, jsonStringify } from './formatters';
const oneTimeMetrics = new Set();
// In case we need to override the theme for VR.
let theme = '';
function setTheme(newTheme) {
    theme = newTheme;
}
export class Metrics {
    constructor(source, packageVersion) {
        this.clog = new CLogClient();
        this.panorama = new PanoramaClient();
        this.source = source;
        this.packageVersion = packageVersion;
    }
    initMetrics(theme) {
        setTheme(theme);
    }
    /**
     * Calls Console Platform's client logging JS API with provided metric name, value, and detail.
     * Does nothing if Console Platform client logging JS is not present in page.
     */
    sendMetric(metricName, value, detail) {
        if (!theme) {
            // Metrics need to be initialized first (initMetrics)
            console.error('Metrics need to be initialized first.');
            return;
        }
        this.clog.sendMetric(metricName, value, detail);
    }
    /**
     * Calls Console Platform's client v2 logging JS API with provided metric name and detail.
     * Does nothing if Console Platform client logging JS is not present in page.
     */
    sendPanoramaMetric(metric) {
        this.panorama.sendMetric(metric);
    }
    sendMetricObject(metric, value) {
        this.sendMetric(buildMetricName(metric, theme), value, buildMetricDetail(metric, theme));
    }
    sendMetricObjectOnce(metric, value) {
        const metricKey = jsonStringify(metric);
        if (!oneTimeMetrics.has(metricKey)) {
            this.sendMetricObject(metric, value);
            oneTimeMetrics.add(metricKey);
        }
    }
    /*
     * Calls Console Platform's client logging only the first time the provided metricName is used.
     * Subsequent calls with the same metricName are ignored.
     */
    sendMetricOnce(metricName, value, detail) {
        if (!oneTimeMetrics.has(metricName)) {
            this.sendMetric(metricName, value, detail);
            oneTimeMetrics.add(metricName);
        }
    }
    /*
     * Reports a metric value 1 to Console Platform's client logging service to indicate that the
     * component was loaded. The component load event will only be reported as used to client logging
     * service once per page view.
     */
    logComponentsLoaded() {
        this.sendMetricObjectOnce({ source: this.source, action: 'loaded', version: this.packageVersion }, 1);
    }
    /*
     * Reports a metric value 1 to Console Platform's client logging service to indicate that the
     * component was used in the page.  A component will only be reported as used to client logging
     * service once per page view.
     */
    logComponentUsed(componentName, configuration) {
        this.sendMetricObjectOnce({
            source: componentName,
            action: 'used',
            version: this.packageVersion,
            configuration,
        }, 1);
    }
}
export function clearOneTimeMetricsCache() {
    oneTimeMetrics.clear();
}
export class MetricsTestHelper {
    constructor() {
        this.resetOneTimeMetricsCache = clearOneTimeMetricsCache;
    }
}
