
    import './styles.scoped.css';
    export default {
  "gen-ai": "awsui_gen-ai_12y5i_lygyv_19",
  "gradientMove": "awsui_gradientMove_12y5i_lygyv_1",
  "gradientMoveReverse": "awsui_gradientMoveReverse_12y5i_lygyv_1",
  "dot": "awsui_dot_12y5i_lygyv_81",
  "dotsDancing": "awsui_dotsDancing_12y5i_lygyv_1",
  "root": "awsui_root_12y5i_lygyv_162",
  "typing": "awsui_typing_12y5i_lygyv_176"
};
  