
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_cdujn_18ie7_11",
  "message-area": "awsui_message-area_cdujn_18ie7_45",
  "with-loading-bar": "awsui_with-loading-bar_cdujn_18ie7_55",
  "chat-bubble-type-outgoing": "awsui_chat-bubble-type-outgoing_cdujn_18ie7_58",
  "chat-bubble-type-incoming": "awsui_chat-bubble-type-incoming_cdujn_18ie7_62",
  "avatar": "awsui_avatar_cdujn_18ie7_67",
  "hide": "awsui_hide_cdujn_18ie7_70",
  "content": "awsui_content_cdujn_18ie7_74",
  "actions": "awsui_actions_cdujn_18ie7_78"
};
  