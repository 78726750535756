import React from 'react';
import TopNavigation from "@cloudscape-design/components/top-navigation";
import './TopBar.scss';

const logo = new URL('../images/app-logo.svg', import.meta.url);


function TopBar({ isSignedIn }) {
  
  return (
    <TopNavigation
      identity={{
        href: "/",
        logo: {
          src: logo.pathname,
          alt: "Devpen"
        }
      }}
      utilities={isSignedIn ? [
        {
          type: "button",
          iconName: "user-profile",
          href: "/profile",
          title: "User Profile",
          ariaLabel: "User Profile Link",
        },
      ] : []}
    >
    </TopNavigation>
  );
}

export default TopBar;
