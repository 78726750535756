import React, { useEffect, useState } from "react";
import Drawer from "@cloudscape-design/components/drawer";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ChatBubble from "@cloudscape-design/chat-components/chat-bubble";
import Avatar from "@cloudscape-design/chat-components/avatar";
import { useAtom } from "jotai";
import { askQuestionAtom, chatAtom, initialChat } from "../store";
import Button from "@cloudscape-design/components/button";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import LiveRegion from "@cloudscape-design/components/live-region";
import { useParams } from "react-router-dom";

// TODO: This drawer currently only handles single task. Once we enable the chat feature we need to build a 
//   conversation style thread of the I/O messages.
export default () => {
    const [chat, setChat] = useAtom(chatAtom);
    const [, askQuestion] = useAtom(askQuestionAtom);
    const { noteId } = useParams();


    useEffect(() => {
        setChat(initialChat);
    }, [noteId]);

    const isChatResponseLoaded = chat.messages.length;


    return (
        <Drawer header={<h2>Chat</h2>}>
            {isChatResponseLoaded ? <>
                <Box>
                    <ChatBubble
                        ariaLabel="John Doe at 5:29:02pm"
                        type="outgoing"
                        avatar={
                            <Avatar

                                ariaLabel="John Doe"
                                tooltipText="John Doe"
                                initials="PR"
                            />
                        }
                    >
                        {chat.messages[0].user}
                    </ChatBubble>
                </Box>
                <Box>
                    {/* TODO: Replace the avatar with user name */}
                    <ChatBubble
                        ariaLabel="John Doe at 5:29:02pm"
                        type="incoming"
                        avatar={
                            <Avatar
                                ariaLabel="Avatar of generative AI assistant"
                                color="gen-ai"
                                iconName="gen-ai"
                                tooltipText="Generative AI assistant"
                            />
                        }
                    >
                        {chat.messages[0].system}
                    </ChatBubble>
                </Box></> : null}
                <SpaceBetween direction="vertical" size="l">
            <Box textAlign="center">
                {chat.isLoading ? <LiveRegion>
                    <Box
                        margin={{ bottom: "xs", left: "l" }}
                        color="text-body-secondary"
                    >
                        Generating a response
                    </Box>
                    <LoadingBar variant="gen-ai" />
                </LiveRegion> : null}
            </Box>
            <Box textAlign="center">
                {isChatResponseLoaded ? null : <Button variant="primary" onClick={() => askQuestion({
                    noteId,
                    messages: [{
                        user: 'Summarise this note',
                        system: ''
                    }]
                })}>Summarize</Button>}
            </Box>
            {/* TODO: Add prompt input box once chat feature is built. */}
            </SpaceBetween>
            
        </Drawer>
    );
}