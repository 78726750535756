import React, { ReactElement } from "react";
import TopBar from "../molecules/TopBar";
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import AppLayout from '@cloudscape-design/components/app-layout';
import { isSignedIn } from '../services/auth-service';
import { BreadcrumbGroup, Icon } from "@cloudscape-design/components";
import Drawer from "@cloudscape-design/components/drawer";
import ChatDrawer from "./ChatDrawer";
import { useAtom } from "jotai";
import { chatDrawerAtom } from "../store";

interface LayoutProps {
  children: ReactElement,
  breadCrumbItems: { href: string; text: string }[]
}

const Layout: React.FC<LayoutProps> = ({ children, breadCrumbItems = [] }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useAtom(chatDrawerAtom);

  const handleBreadCrumbNavigation = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  }

  const topbar = <TopBar
    isSignedIn={isSignedIn()}
  />

  const breadCrumbItemsWithParams = breadCrumbItems.map(item => ({
    ...item,
    href: generatePath(item.href, params)
  }));

  const breadCrumb = <BreadcrumbGroup
    items={breadCrumbItemsWithParams}
    onClick={handleBreadCrumbNavigation}
  />

  const activeDrawerId = isChatDrawerOpen ? 'chat-drawer' : null;
  const drawers = [{
    id: 'chat-drawer',
    content: <ChatDrawer />,
    ariaLabels: {
      drawerName: 'Chat'
    }
  }];

  return (
    <div>
      {topbar}
      <AppLayout content={
        children
      }
        drawers={activeDrawerId?drawers:[]}
        activeDrawerId={activeDrawerId}
        navigationHide
        onDrawerChange={() => setIsChatDrawerOpen(!isChatDrawerOpen)}
        breadcrumbs={breadCrumbItemsWithParams?.length ? breadCrumb : undefined}
      /></div>
  );
};

export default Layout;