import { API_URL } from "../constants";
import { buildAuthTokenHeader } from "./auth-service";

export async function getNotesForUser() {
    const response = await fetch(`${API_URL}/notes`, {
        method: 'GET',
        headers: {
            authorization: buildAuthTokenHeader()
        }
    });
    const notes = await response.json();
    return notes;
}

export async function getNoteById(id: string) {
    const response = await fetch(`${API_URL}/notes/${id}`, {
        method: 'GET',
        headers: {
            authorization: buildAuthTokenHeader()
        }
    });
    const { note } = await response.json();
    return note;
}

// TODO: Remove log and integrate with chat api
export async function summarizeNote(id: string) {
    const response = await fetch(`${API_URL}/notes/${id}/summarize`, {
        method: 'GET',
        headers: {
            authorization: buildAuthTokenHeader()
        }
    });
    const summary = await response.json();
    return summary
}

export async function saveNote(note: any) {
    const response = await fetch(`${API_URL}/notes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: buildAuthTokenHeader(),
        },
        body: JSON.stringify(note)
    });
    const { id } = await response.json();
    return id;
}


export async function deleteNote(noteId: string) {
    const response = await fetch(`${API_URL}/notes/${noteId}`, {
        method: 'DELETE',
        headers: {
            authorization: buildAuthTokenHeader()
        }
    });
    const { status } = await response.json();
    return status;
}

