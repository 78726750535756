import { atom } from 'jotai';
import { summarizeNote } from '../services/notes-service';

// Define the structure of a message
interface Message {
  user: string;
  system: string;
}

// Define the initial chat state type
interface ChatState {
  isLoading: boolean;
  noteId: null | string;
  messages: Message[];
}


export const chatDrawerAtom = atom(false);

export const initialChat: ChatState = {
  noteId: null,
  messages: []
};

export const chatAtom = atom<ChatState>(initialChat);

export const askQuestionAtom = atom(
  null,
  async (get, set, newMessage: Partial<ChatState>) => {
    const currentChatState = get(chatAtom);
    set(chatAtom, {
      ...currentChatState,
      isLoading: true
    });
    const response = await summarizeNote(newMessage.noteId)
    set(chatAtom, {
      ...currentChatState,
      isLoading: false,
      messages: [
        {
          user: newMessage?.messages[0].user,
          system: response.summary.content
        }
      ],
    });
  }
);
